app-left.full {
  width: 200px;

  app-navigation {
    margin-left: 10px;
    .tooltip {
      display: none !important;
    }
  }
}

app-left.small {
  width: 50px;

  app-bookmarks, app-last-visit {
    display: none;
  }

  app-navigation {
    margin: 5px 15px;
    .tooltip {
      display: initial !important;
      margin-left: 15px;
    }
  }

  .nav-link, .nav-link.active {
    color: #ffffff !important;
  }

  .nav-link.active {
    transform-origin: center center;
    transform: scale(1.5);
  }
}

app-left.hidden {
  width: 0;

  app-navigation,
  app-bookmarks {
    display: none;
  }
}

app-right.full {
  width: 300px;

  .card,
  button {
    display: block;
  }

  .count {
    display: none;
  }
}

app-right.small {
  width: 50px;

  .count {
    display: block;
  }

  .card,
  button {
    display: none;
  }
}

app-right.hidden {
  width: 0;

  .count,
  .card,
  button{
    display: none;
  }
}

.btnLeft, .btnRight {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0,0,0,0.12);
  &.btn {
    border: 1px solid #DAD9D9;
  }
}
