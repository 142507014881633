/******   LAYOUT   *******/
@import "app/shared/styling/datatable-bootstrap";
@import "app/shared/styling/sidebars";
@import "app/shared/styling/variables";
@import "~bootstrap/scss/bootstrap";
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "app/base/base-list/directives/sidebar-table.directive.scss";
@import "app/trix/trix.style.scss";

body, html, app-root {
  height: 100%;
  overflow: hidden;
}

a {
  cursor: pointer;
}

.main {
  a {
    color: inherit !important;
  }
}

.toast-container .toast {
  opacity: 1;
}

.table {
  table-layout: fixed;
}

.table td.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.bootstrap.fullscreen {
  position: absolute !important;
  height: auto !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.overlay {
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: .35;
}

.cols {
  margin-top: 0.5em;
  column-count: 4;

  dl {
    margin-bottom: 0;
    break-inside: avoid;
    dd {
      min-height: 20px;
    }
  }
}

@media only screen and (max-width: 1900px) {
  .cols {
    column-count: 3;
  }
}

@media only screen and (max-width: 1400px) {
  .cols {
    column-count: 2;
  }
}

@media only screen and (max-width: 1000px) {
  .cols {
    column-count: 1;
  }
}

h4 {
  min-height: 23px;
}

accordion {
  margin-top: 5px !important;
  accordion-group {
    padding: 0 !important;
    margin: 0 !important;
    margin-top: 5px !important;
  }
}

.cardhead {
  padding: 0 !important;
  margin: 0 !important;
  margin-top: -5px !important;
  height: 15px !important;
}

.tablecols > div {
  background: #fafafa;
  margin-bottom: 10px;
  h6 {
    padding: 10px;
    text-align: center;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    margin: 0;
  }
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
  break-inside: avoid;
}

//ngx-datatable ::selection {
//  user-select: none;
//}

