.table-row-clickable {
  cursor: pointer;
}

.table-row-active {
  background: #c9c9c9 !important;
}

.ngx-datatable.bootstrap.scrollable-table {
  .datatable-footer {
    box-shadow: 0 -6px 20px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #ddd;
    background: #fff;
    color: #666;
    padding: 0px 10px;
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;

    ul li {
      &:not(.disabled).active a, &:not(.disabled):hover a {
        background: #888;
        color: #fff !important;
      }
    }

    .page-count {
      line-height: 20px;
      height: 20px;
      padding: 0;
    }
  }

  .datatable-scroll {
    margin-bottom: 0.5em;
  }
}
