.trix-text {
  text-align: left;
  font-size: 15px;

  h3 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 8px 0;
  }

  del {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
  }

  um {
    text-decoration: underline 2px;
  }

  ul, ol {
    margin: 15px 0;
  }

  li > ol, li > ul {
    margin: 0;
  }
}
