.ngx-datatable.bootstrap {
  box-shadow: none;
  .datatable-header {
    height: unset !important;
    .datatable-header-cell {
      vertical-align: bottom;
      border-bottom: 1px solid #cdcdcd;
      .datatable-header-cell-label {
        line-height: 24px;
      }
    }
  }

  .datatable-body {
    .datatable-body-row {
      vertical-align: top;
      border-bottom: 1px solid #d1d4d7;
      &:hover {
        background-color: #ddd;
      }
      &.datatable-row-even {
        background-color: rgba(0, 0, 0, 0.01);
        &:hover {
          background-color: #ddd;
        }

        &.active {
          background-color: #1483ff;
          color: #FFF;
        }
      }
      &.active {
        background-color: #1483ff;
        color: #FFF;
      }
      .datatable-body-cell {
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        label {
          margin-bottom: 0 !important;
        }
        * {
          line-height: 0 !important;
        }
      }
    }
  }

  .datatable-footer {
    background: #424242;
    color: #ededed;
    margin-top: -1px;
    .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 1.2rem;
    }
    .datatable-pager {
      margin: 0 10px;
      vertical-align: top;
      ul {
        li {
          margin: 10px 0px;
          &:not(.disabled) {
            &.active,
            &:hover {
              a {
                background-color: #545454;
                font-weight: bold;
              }
            }
          }
        }
      }
      a {
        height: 26px;
        min-width: 24px;
        line-height: 26px;
        padding: 0;
        border-radius: 3px;
        margin: 0 3px;
        text-align: center;
        text-decoration: none;
        color: #ededed;
      }
      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
        padding: 0 3px;
      }
    }
  }

  .datatable-checkbox {
    margin-left: 5px;
  }
}
